import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { interval } from "rxjs";
import { takeWhile } from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class AutoScrollService {
  //private readonly router = inject(Router);
//  private readonly viewportScroller = inject(ViewportScroller);
 // private destroyRef = inject(DestroyRef);

  //shouldScroll = new BehaviorSubject<boolean>(false);
  lastPoppedUrl ;
  yScrollStack: number[] = [];
  scrollInterval : number = 500;
  constructor( private router: Router) {
  //   this.location.subscribe((ev: PopStateEvent) => {
  //     this.lastPoppedUrl = ev.url;
  // });
 

 
}
init() {
  this. lastPoppedUrl ="/"+ location.hash;
  this.router.events.subscribe((ev: any) => {
    if (ev instanceof NavigationStart) {
      if (ev.url !== this.lastPoppedUrl) {
        this.yScrollStack.push(window.scrollY);
      } else {
        this.lastPoppedUrl = undefined;
        const yposition = this.yScrollStack.pop();
        let maxInterval = 0; // used to stop subscription
        interval(this.scrollInterval)
          .pipe(
            takeWhile(_ => window.scrollY < yposition && maxInterval < 5000)
          )
          .subscribe(_ => {
            maxInterval += this.scrollInterval;
            window.scrollTo({
              top: yposition,
              left: 0,
              behavior: 'smooth',
            });
          });
      }
    }
  });
}
  }

 

